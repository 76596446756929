


























































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Franchise, Office, OfficeExternalLinkType, Profile, WebsiteLevel, WebsiteProfileDisplayType } from 'client-website-ts-library/types';
import { API, Config, Logger, LogLevel } from 'client-website-ts-library/services';
import { ProfileFilter } from 'client-website-ts-library/filters';

import NewsletterSignupBar from '../NewsletterSignupBar.vue';
import Profiles from '../Profiles.vue';
import AgentCardCycler from '../Cycler/AgentCardCycler.vue';
import SideBySide from '../Layout/SideBySide.vue';
import AwardsCycler from '../Cycler/AwardsCycler.vue';
import FooterButtonCardCycler from '../Cycler/FooterButtonCardCycler.vue';

@Component({
  components: {
    AgentCardCycler,
    NewsletterSignupBar,
    Profiles,
    SideBySide,
    AwardsCycler,
    FooterButtonCardCycler,
  },
})
export default class Footer extends Vue {
  private offices: Office[] = [];

  public office: Office | null = null;

  private franchise: Franchise | null = null;

  public profiles: Profile[] = [];

  mounted() {
    this.loadOffices();

    API.Profiles.Search(
      new ProfileFilter({
        SearchLevel: WebsiteLevel.Office,
        SearchGuid: Config.Website.Settings!.WebsiteId,
      }),
      true,
    ).then((profiles) => {
      // this.profiles = profiles.filter((p) => p.DisplayType === WebsiteProfileDisplayType.Agent);
      this.profiles = profiles;
    });
  }

  loadOffices(): void {
    switch (Config.Website.Settings!.WebsiteLevel) {
      case WebsiteLevel.Profile:

        API.Profiles.Get(Config.Website.Settings!.WebsiteId, true).then((profile) => {
          this.office = profile.Office;
        });

        break;
      case WebsiteLevel.Office:

        API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
          this.office = office;
        });

        break;
      case WebsiteLevel.Franchise:

        API.Franchises.GetOffices(Config.Website.Settings!.WebsiteId).then((offices) => {
          this.offices = offices;
        });

        API.Franchises.Get(Config.Website.Settings!.WebsiteId).then((franchise) => {
          this.franchise = franchise;
        });

        break;
      default:
        Logger.Log(LogLevel.Error, 'Failed to load office for website. Website level is invalid.');

        break;
    }
  }

  // eslint-disable-next-line
  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  get facebookLink(): string | undefined {
    return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.Facebook)?.Url;
  }

  get youtubeLink(): string | undefined {
    return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.YouTube)?.Url;
  }

  get twitterLink(): string | undefined {
    return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.Twitter)?.Url;
  }

  get googleMyBusinessLink(): string | undefined {
    return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.GoogleMyBusiness)?.Url;
  }

  get linkedinLink(): string | undefined {
    return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.LinkedIn)?.Url;
  }

  get pinterestLink(): string | undefined {
    return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.Pinterest)?.Url;
  }

  get instagramLink(): string | undefined {
    return this.office?.ExternalLinks.find((l) => l.Type === OfficeExternalLinkType.Instagram)?.Url;
  }

  // eslint-disable-next-line
  get year(): string {
    return new Date().getFullYear().toString();
  }

  get hideMap(): boolean {
    return this.$route.meta?.hideOfficeMap;
  }
}
