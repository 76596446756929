





























































































import { Vue, Component } from 'vue-property-decorator';

import { CarouselBreakpoint } from '@/app_code/CarouselBreakpoint';

import Cycler from './Cycler.vue';
import CyclerItem from './CyclerItem.vue';

@Component({
  components: {
    Cycler,
    CyclerItem,
  },
})
export default class FooterButtonCardCycler extends Vue {
  private isMobile = window.innerWidth < 768;

  public breakpoints: CarouselBreakpoint[] = [
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1120,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 6,
      },
    },
  ];
}
