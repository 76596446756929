



















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ContentRow extends Vue {
  @Prop()
  public readonly backgroundColour!: string;

  @Prop()
  public readonly darken!: boolean;

  @Prop()
  public readonly backgroundImage!: string;

  @Prop()
  public readonly noPadding!: boolean;
}
