import { render, staticRenderFns } from "./AwardsCycler.vue?vue&type=template&id=69c7c880&scoped=true&"
import script from "./AwardsCycler.vue?vue&type=script&lang=ts&"
export * from "./AwardsCycler.vue?vue&type=script&lang=ts&"
import style0 from "./AwardsCycler.vue?vue&type=style&index=0&id=69c7c880&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69c7c880",
  null
  
)

export default component.exports