

































import { Vue, Component, Prop } from 'vue-property-decorator';

import { CarouselBreakpoint } from '@/app_code/CarouselBreakpoint';

import { Profile } from 'client-website-ts-library/types';
import Cycler from './Cycler.vue';
import CyclerItem from './CyclerItem.vue';
import ProfileCard from '../ProfileCard.vue';

@Component({
  components: {
    Cycler,
    CyclerItem,
    ProfileCard,
  },
})
export default class AgentCardCycler extends Vue {
  @Prop()
  public agentProfiles: Profile[] | null = null;

  @Prop({ default: '' })
  public readonly id!: string;

  public isMobile = window.innerWidth < 768;

  public breakpoints: CarouselBreakpoint[] = [
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 950,
      settings: {
        slidesToShow: 4,
      },
    },
  ];

  created() {
    window.addEventListener('resize', this.resizeHandler);
  }

  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  resizeHandler() {
    this.isMobile = window.innerWidth < 768;
  }
}
