
































import { Vue, Component } from 'vue-property-decorator';

import { CarouselBreakpoint } from '@/app_code/CarouselBreakpoint';

import Cycler from './Cycler.vue';
import CyclerItem from './CyclerItem.vue';

@Component({
  components: {
    Cycler,
    CyclerItem,
  },
})
export default class AwardsCycler extends Vue {
  public isMobile = window.innerWidth < 768;

  public breakpoints: CarouselBreakpoint[] = [
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1.5,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1.5,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2.5,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1120,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 2000,
      settings: {
        slidesToShow: 3,
      },
    },
  ];
}
