



















import { API } from 'client-website-ts-library/services';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Loader from './UI/Loader.vue';

@Component({
  components: {
    Loader,
  },
})
export default class NewsletterSignupBar extends Vue {
  public email = '';

  public loading = false;

  public complete = false;

  subscribe() {
    this.loading = true;

    API.CRM.CreateContact('', '', this.email, '').then(() => {
      this.loading = false;
      this.complete = true;
    });
  }
}
