
















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Card extends Vue {
  @Prop()
  public readonly href!: string;

  @Prop()
  public readonly externalLink!: boolean;

  @Prop()
  public readonly internalLink!: boolean;
}
