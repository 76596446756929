























import { Component, Vue, Prop } from 'vue-property-decorator';
import Masthead from './Common/Masthead.vue';
import ListingSearch from './ListingSearch.vue';

@Component({
  components: {
    Masthead,
    ListingSearch,
  },
})
export default class SiteMasthead extends Vue {
  @Prop()
  public readonly mini!: boolean;

  @Prop()
  public readonly title!: string;

  @Prop()
  public readonly subtitle!: string;

  @Prop({
    default: {
      source: '/assets/images/default/masthead',
      formats: ['avif', 'webp', 'jpg'],
      fallback: 'jpg',
    },
  })
  public readonly image!: string;

  @Prop({ default: true })
  public readonly darken!: boolean;

  @Prop({ default: false })
  public readonly extraDark!: boolean;

  @Prop()
  public readonly height!: string;
}
