var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cards",style:({
    '--per-row': _vm.perRow,
    '--spacing': _vm.spacing,
    '--md-per-row': _vm.mdPerRow,
    '--sm-per-row': _vm.smPerRow,
    '--xs-per-row': _vm.xsPerRow,
    'justify-content': _vm.hAlign,
    'align-items': _vm.vAlign,
  })},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }