
























































































import { Vue, Component } from 'vue-property-decorator';

import { CarouselBreakpoint } from '@/app_code/CarouselBreakpoint';

import Cycler from './Cycler.vue';
import CyclerItem from './CyclerItem.vue';

@Component({
  components: {
    Cycler,
    CyclerItem,
  },
})
export default class ButtonCardCycler extends Vue {
  public breakpoints: CarouselBreakpoint[] = [
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 1.8,
      },
    },
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 2.5,
      },
    },
    {
      breakpoint: 660,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 3.4,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 4.2,
      },
    },
  ];
}
