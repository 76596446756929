




















import { Component, Vue, Prop } from 'vue-property-decorator';

import { ImageSourceSet } from 'client-website-ts-library/types';

@Component
export default class SideBySide extends Vue {
  @Prop()
  public readonly image!: string | ImageSourceSet;

  @Prop()
  public readonly reversed!: boolean;

  @Prop()
  public readonly background!: string;

  @Prop()
  public readonly border!: string;

  @Prop({ default: '530px' })
  public readonly containerMaxWidth!: string;
}
