






















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class LinkButton extends Vue {
  @Prop()
  private readonly href!: string;

  @Prop({ default: true })
  public readonly margin!: boolean;

  @Prop({ default: false })
  public readonly shadow!: boolean;

  @Prop({ default: '' })
  public readonly width!: string;

  @Prop({ default: '' })
  public readonly height!: string;

  @Prop({ default: '1em' })
  public readonly padding!: string;

  @Prop({ default: '1em' })
  public readonly borderRadius!: string;

  @Prop()
  private readonly external!: boolean;

  @Prop({ default: '_blank' })
  private readonly target!: string;

  @Prop()
  public readonly colour!: string;

  @Prop()
  public readonly backgroundColour!: string;

  @Prop()
  public readonly hoverColour!: string;

  @Prop()
  public readonly icon!: string;

  @Prop({ default: 'white' })
  public readonly iconColour!: string;

  @Prop()
  public readonly block!: boolean;

  @Prop({ default: '' })
  public readonly border!: string;

  @Prop({ default: 'auto' })
  public readonly marginTop!: string;

  @Prop({ default: 'auto' })
  public readonly marginRight!: string;

  clicked(): void {
    this.$emit('clicked');

    if (this.external) {
      if (this.target === '_self') {
        window.location.href = this.href;
      } else {
        window.open(this.href, this.target);
      }
    } else {
      this.$router.push(this.href);
    }
  }
}
